import React from 'react';
import { Normalize } from 'styled-normalize';

import BoxSizingStyles from './src/styles/boxSizing';

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => (
  <React.Fragment>
    <Normalize />
    <BoxSizingStyles />
    {element}
  </React.Fragment>
);
